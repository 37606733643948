<template>
    <v-card class="new-trait-card pa-5">
        <v-card-title>
            <h5 class="text-h5">
                {{ title }}
            </h5>
        </v-card-title>
        <v-card-text class="px-0 px-md-2">
            <v-form ref="formRef" v-model="formValid" lazy-validation>
                <v-container class="px-0">
                    <v-row no-gutters>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newTrait.attribute" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Attribute" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newTrait.collection_percentage" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Collection Percentage" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newTrait.value" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Value" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newTrait.rarity" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Rarity" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newTrait.tier_1" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Tier 1" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newTrait.tier_2" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Tier 2" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newTrait.tier_3" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Tier 3" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newTrait.missions_qualified" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Missions Qualified" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newTrait.weeks_qualified" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Weeks Qualified" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newTrait.raids_qualified" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Raids Qualified" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
            <v-btn @click="handleClose">
                Cancel
            </v-btn>
            <v-btn color="accent" :loading="loading" @click="handleAction">
                Save
            </v-btn>
        </v-card-actions>

        <v-snackbar v-model="snackbar" :timeout="10000">
            {{ message }}

            <template v-slot:actions>
                <v-btn color="blue" variant="text" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <v-overlay v-model="loading" contained class="align-center justify-center">
            <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
        </v-overlay>
    </v-card>
</template>

<script setup>
import { ref, defineEmits, onMounted } from 'vue'

const props = defineProps({
    traitToUpdate: Object,
    editMode: Boolean,
    idToUpdate: String
})
const emit = defineEmits(['cancel'])

const snackbar = ref(false)
const message = ref('')
const loading = ref(false)

const title = ref('New Trait')

const formRef = ref(null)
const formValid = ref(false)

const formRules = ref([
    v => !!v || 'This field is required'
])

const newTrait = ref({
    attribute: '',
    collection_percentage: '',
    combined: '',
    rarity: '',
    tier_1: '',
    tier_2: '',
    tier_3: '',
    value: '',
    missions_qualified: 0,
    weeks_qualified: 0,
    raids_qualified: 0
})

onMounted(() => {
    if (props.editMode) {
        newTrait.value = props.traitToUpdate
        title.value = 'Update Trait'
    }
})

function handleClose() {
    emit('close')
}

async function handleAction() {
    if (validateForm()) {
        loading.value = true
        if (!props.editMode) {
            // create trait

            // parse int values
            newTrait.value.missions_qualified = parseInt(newTrait.value.missions_qualified)
            newTrait.value.weeks_qualified = parseInt(newTrait.value.weeks_qualified)
            newTrait.value.raids_qualified = parseInt(newTrait.value.raids_qualified)

            await axios.post('https://ff-backend-api-kpojlq5hbq-uc.a.run.app/api/traits', newTrait.value)
                .then(response => {
                    emit('created')
                    handleClose()
                })
                .catch(error => {
                    snackbar.value = true
                    message.value = error
                })

        } else {
            // update trait
            
            // parse int values
            newTrait.value.missions_qualified = parseInt(newTrait.value.missions_qualified)
            newTrait.value.weeks_qualified = parseInt(newTrait.value.weeks_qualified)
            newTrait.value.raids_qualified = parseInt(newTrait.value.raids_qualified)

            await axios.patch(`https://ff-backend-api-kpojlq5hbq-uc.a.run.app/api/traits/${parseInt(newTrait.value.combined)}`, newTrait.value)
                .then(response => {
                    emit('updated')
                    handleClose()
                })
                .catch(error => {
                    snackbar.value = true
                    message.value = error
                })
        }
        loading.value = false
    } else {
        snackbar.value = true
        message.value = 'Fill at least attribute and value.'
    }
}

function validateForm() {
    return newTrait.value.attribute.length && newTrait.value.value.length
}

</script>

<style scoped>
.new-trait-card {
    margin: 0 auto;
    width: 60%;
}

@media screen and (max-width: 768px) {
    .new-trait-card {
        width: 100%;
    }
}
</style>