<template>
    <div class="mt-4 mt-md-14 px-3 px-md-8">
        <div>
            <h1>
                Search FFF
            </h1>
        </div>
        <div class="d-flex flex-column flex-md-row mt-md-10 pb-10 pb-md-0">
            <div class="search-input-container">
                <span class="text-subtitle-1">
                    Type the number of the FFF you are looking for
                </span>
                <v-text-field v-model="searchNumber" class="search-input mt-4" label="FFF Number" type="number"
                    variant="outlined" />
                <v-btn block color="accent" flat size="large" @click="searchNFT">
                    Search
                    <v-icon icon="mdi-magnify" class="ml-3" />
                </v-btn>
            </div>
            <div v-if="showFound" class="result-container ml-md-16 pl-md-8">
                <h6 class="text-h6">
                    Result of your search:
                </h6>
                <FoxDetails :fox="fox" /> 
            </div>
            <div v-if="noResults">
                <div class="ml-15 mt-15">
                    <h4 class="text-h4">
                        Sorry, we couldn't find any FFF with that number.
                    </h4>
                </div>
            </div>
        </div>

        <v-overlay v-model="loading" contained class="align-center justify-center">
            <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
        </v-overlay>

        <v-snackbar v-model="snackbar" :timeout="10000">
            {{ message }}

            <template v-slot:actions>
                <v-btn color="blue" variant="text" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import axios from 'axios'
import FoxDetails from '@/components/Foxes/FoxDetails.vue'

const snackbar = ref(false)
const message = ref('')

const loading = ref(false)

const noResults = ref(false)
const showFound = ref(false)

// for the search
const searchNumber = ref('')

// data
const fox = reactive({
    foxData: {},
    foxTraits: []
})

async function searchNFT() {
    if (searchNumber.value.length) {
        loading.value = true
        noResults.value = false
        showFound.value = false
        
        // search for NFT
        await axios.get(`https://ff-backend-api-kpojlq5hbq-uc.a.run.app/api/foxes/${parseInt(searchNumber.value)}`)
            .then(response => {
                console.log('response:', response.data)
                fox.foxData = response.data
                fox.foxTraits = parseTraitsArray(response.data.all_traits)
                showFound.value = true
            })
            .catch(error => {
                if (error.response.status === 404 && error.response.data.message === 'Fox not found') {
                    noResults.value = true
                } else {
                    snackbar.value = true
                    message.value = error
                }
            })

        loading.value = false
    } else {
        snackbar.value = true
        message.value = 'Please type a number to search NFTs'
    }
}

function parseTraitsArray(traits) {
    return traits.split(', ')
}
</script>

<style scoped>
h3,
h4,
h5 {
    font-weight: bold;
}

.search-input-container {
    max-width: 350px;
}
</style>