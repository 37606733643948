<template>
    <v-app data-app>
        <!-- test label -->
        <v-card>
            <v-layout>
                <v-app-bar v-if="!mdAndUp" :elevation="2" color="accent">
                    <template v-slot:prepend>
                        <v-avatar>
                            <v-img :src="require('@/assets/images/fff-logo.png')"></v-img>
                        </v-avatar>
                    </template>
                    <v-app-bar-title>
                        Famous Foxes Missions
                    </v-app-bar-title>
                    <template v-if="loggedIn" v-slot:append>
                        <v-app-bar-nav-icon @click="showMobileDrawer = !showMobileDrawer"></v-app-bar-nav-icon>
                    </template>
                </v-app-bar>

                <v-navigation-drawer v-if="mdAndUp || showMobileDrawer" class="bg-accent" expand-on-hover permanent
                    :rail="mdAndUp" :location="mdAndUp ? 'start' : 'end'">
                    <v-list v-if="mdAndUp">
                        <v-list-item :prepend-avatar="require('@/assets/images/fff-logo.png')"
                            title="Famous Foxes"></v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-list>
                        <router-link v-if="loggedIn" to="/admin" style="text-decoration: none; color: inherit;">
                            <v-list-item prepend-icon="mdi-view-dashboard" title="Manage Database" value="manageDatabase" />
                        </router-link>

                        <v-list-item v-else prepend-icon="mdi-lock-open" title="Admin Access" value="manageDatabase"
                            @click="loginMode = true" />
                    </v-list>

                    <v-divider></v-divider>

                    <v-list density="compact" nav>
                        <router-link to="/traits" style="text-decoration: none; color: inherit;">
                            <v-list-item prepend-icon="mdi-book-open-page-variant" title="Traits List" value="traitslist"
                                @click="loginMode = false"></v-list-item>
                        </router-link>
                        <router-link to="/foxes" style="text-decoration: none; color: inherit;">
                            <v-list-item prepend-icon="mdi-format-list-text" title="All FFF" value="nftsList"
                                @click="loginMode = false"></v-list-item>
                        </router-link>
                        <router-link to="/search" style="text-decoration: none; color: inherit;">
                            <v-list-item prepend-icon="mdi-database-search-outline" title="Search FFF" value="search"
                                @click="loginMode = false"></v-list-item>
                        </router-link>
                        <v-list-item v-if="loggedIn" prepend-icon="mdi-logout" title="Sign Out" value="logout"
                            @click="logout()"></v-list-item>
                    </v-list>
                </v-navigation-drawer>

                <v-main style="min-height: 100vh;">
                    <div v-if="loginMode" class="mt-4 mt-sm-14 px-3 px-sm-8 login-container">
                        <h1>Sign In</h1>
                        <div class="d-flex flex-column mt-10">
                            <v-text-field v-model="email" label="Email" variant="outlined" />
                            <v-text-field v-model="password" label="Password" variant="outlined" type="password" />
                            <v-btn color="accent" size="large" :loading="btnLoading" @click="login">
                                Continue
                            </v-btn>
                            <v-btn flat class="mt-7" @click="loginMode = false">
                                <v-icon icon="mdi-chevron-left" />
                                Back
                            </v-btn>
                        </div>
                    </div>
                    <div v-else>
                        <router-view></router-view>
                    </div>
                </v-main>
            </v-layout>
        </v-card>

        <v-snackbar v-model="snackbar" :timeout="10000">
            {{ message }}

            <template v-slot:actions>
                <v-btn color="blue" variant="text" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <v-overlay v-model="loading" contained class="align-center justify-center">
            <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
        </v-overlay>
</v-app>
</template>

<script>
import { createVuetify, useDisplay } from 'vuetify'
import { mapGetters } from 'vuex'
import store from '@/store'

export default {
    name: 'App',
    setup() {
        const vuetify = createVuetify()
        const { mdAndUp, lgAndUp } = useDisplay()
        return { vuetify, mdAndUp, lgAndUp }
    },
    data() {
        return {
            email: '',
            password: '',
            snackbar: false,
            message: '',
            loginMode: false,
            loading: false,
            btnLoading: false,
            signUpModal: false,
            showMobileDrawer: false,
            newUser: {
                email: '',
                password: '',
                passwordConfirmation: ''
            }
        }
    },
    computed: {
        ...mapGetters('auth', ['loggedIn', 'user']),
    },
    async created() {
        console.log('LOGGED IN:', this.loggedIn)
        this.loading = true
        await this.$store.dispatch('auth/getCurrentUser')
            .then(async res => {
                await this.$store.dispatch('auth/checkUserPermissions', res.email)
            })
            .catch((error) => {
                if (this.$route.fullPath.includes('admin')) {
                    this.$router.push('/')
                }
            })
        this.loading = false
    },
    methods: {
        async login() {
            if (this.email.length && this.password.length) {
                this.btnLoading = true
                await store.dispatch('auth/login', { email: this.email, password: this.password })
                    .then(async (res) => {
                        this.snackbar = true
                        this.loginMode = false
                        this.message = 'Welcome ' + this.email + '!'
                    })
                    .catch(error => {
                        this.snackbar = true
                        this.message = error.toString().includes('wrong-password') ? 'Wrong credentials!' : error
                    })
                this.btnLoading = false
            } else {
                this.snackbar = true
                this.message = 'Fill in email and password first!'
            }
        },
        async logout() {
            await store.dispatch('auth/logout')
                .then(res => {
                    this.$router.push('/')
                })
                .catch(error => {
                    this.snackbar = true
                    this.message = error
                })
        },
        async createUser() {
            if (!this.newUser.email.length || !this.newUser.password.length || !this.newUser.passwordConfirmation.length) {
                this.snackbar = true
                this.message = 'Fill in all the fields!'
                return
            }
            if (this.newUser.password !== this.newUser.passwordConfirmation) {
                this.snackbar = true
                this.message = "Passwords don't match!"
                return
            }
            this.loading = true
            await store.dispatch('auth/createUser', {
                email: this.newUser.email,
                password: this.newUser.password
            })
                .then(res => {
                })
                .catch(error => {
                    this.snackbar = true
                    this.message = error
                })
            this.signUpModal = false
            this.loading = false
        }
    }
}
</script>

<style scoped>
.login-container {
    max-width: 600px;
}

.sign-up-card {
    width: 500px;
    margin: 0 auto;
}

@media screen and (max-width: 527px) {
    .sign-up-card {
        width: 100%;
    }
}
</style>