<template>
    <div class="mt-4 mt-sm-14 px-3 px-sm-8">
        <div class="container">
            <h1>Admin</h1>
        </div>
        <v-list class="menu-list">
            <router-link to="/admin/foxes" style="text-decoration: none; color: inherit;">
                <v-list-item prepend-icon="mdi-database" class="bg-primary menu-btn" title="Foxes" />
            </router-link>
            <router-link to="/admin/missions" style="text-decoration: none; color: inherit;">
                <v-list-item prepend-icon="mdi-database" class="bg-secondary menu-btn" title="Missions" />
            </router-link>
            <router-link to="/admin/traits" style="text-decoration: none; color: inherit;">
                <v-list-item prepend-icon="mdi-database" class="bg-warning menu-btn" title="Traits" />
            </router-link>
            <router-link to="/admin/raids" style="text-decoration: none; color: inherit;">
                <v-list-item prepend-icon="mdi-database" class="bg-error menu-btn" title="Raids" />
            </router-link>
        </v-list>
    </div>
</template>

<script setup>
</script>

<style scoped>
.menu-list {
    max-width: 500px;
}
.menu-btn {
    border-radius: 10px;
    max-width: 500px;
    margin-bottom: 10px;
}
</style>