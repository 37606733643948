// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
    theme: {
        themes: {
            light: {
                dark: false,
                colors: {
                    primary: '#00E5FF',
                    secondary: '#FFD600',
                    accent: '#2e004b',
                    error: '#B71C1C',
                    warning: '#FFA000',
                    info: '#1A237E',
                    success: '#00C853',
                }
            },
            dark: {
                light: false,
                colors: {
                    // set dark colors later
                }
            },
        },
    },
})
