<template>
    <div class="mt-4 mt-sm-6 mt-md-14 px-3 px-sm-4 px-md-8">
        <div class="d-flex justify-space-between page-header-container">
            <h1>Traits</h1>
            <div class="d-flex flex-row selects-container">
                <v-select v-model="pageSizeModel" label="Page size" class="select-filter-mini ml-2" density="compact"
                    variant="outlined" :items="pageSizes" @update:model-value="changePageSize" />
            </div>
        </div>
        <div class="table-container mt-8">
            <TraitsTable :traits-data.sync="traits" :descending-data.sync="descendingData" @set-filter="setNewFilter" />
            <v-overlay v-model="tableLoading" contained class="align-center justify-center">
                <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
            </v-overlay>
        </div>

        <div class="mt-10 d-flex flex-row justify-end">
            <v-btn icon="mdi-chevron-left" size="x-small" color="primary" :disabled="currentPage === 1" @click="prevPage" />
            <span class="px-4">Page {{ currentPage }}</span>
            <v-btn icon="mdi-chevron-right" size="x-small" color="primary" :disabled="traits.length < pageSize"
                @click="nextPage" />
        </div>

        <v-snackbar v-model="snackbar" :timeout="10000">
            {{ message }}

            <template v-slot:actions>
                <v-btn color="blue" variant="text" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script setup>
// imports
import { ref, onMounted } from 'vue'
import axios from 'axios'
import TraitsTable from '@/components/Traits/TraitsTable.vue'
import { toRaw } from 'vue'

// interface variables
const tableLoading = ref(false)
const loading = ref(false)
const snackbar = ref(false)
const message = ref('')

// filtering data
const orderByFilter = ref('attribute')
const descendingData = ref(false)

// pagination
const pageSizes = ref([5, 10, 15, 20, 25, 30, 35, 40, 45, 50])
const pageSize = ref(15)
const pageSizeModel = ref(null)
const currentPage = ref(1)

// data
const traits = ref([])

onMounted(() => {
    callForTraits()
})

async function callForTraits() {
    tableLoading.value = true

    let url = 'https://ff-backend-api-kpojlq5hbq-uc.a.run.app/api/traits'

    url += '?page=' + currentPage.value
    url += '&orderBy=' + orderByFilter.value
    url += '&direction=' + (descendingData.value ? 'DESC' : 'ASC')
    url += '&size=' + pageSize.value

    await axios.get(url).then(response => {
        traits.value = response.data
    })
    .catch(error => {
        snackbar.value = true
        message.value = error
    })
    tableLoading.value = false
}

function nextPage() {
    currentPage.value++
    callForTraits()
}

function prevPage() {
    currentPage.value--
    callForTraits()
}

// manipulating table
function changeFilter(newFilter) {
    orderByFilter.value = newFilter
    currentPage.value = 1
    callForTraits()
}

async function setNewFilter(newFilter) {
    await changeAscendingOption()
    changeFilter(newFilter)
}

function changeAscendingOption() {
    return new Promise((resolve, reject) => {
        descendingData.value = !descendingData.value
        resolve()
    })
}

function changePageSize(newPageSize) {
    pageSize.value = newPageSize
    currentPage.value = 1
    callForTraits()
}

</script>

<style scoped>
.page-header-container {
    width: 100%;
}

.table-container {
    min-height: 700px;
}

.select-filter {
    min-width: 200px;
}

.select-filter-mini {
    min-width: 150px;
}
</style>