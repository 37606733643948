<template>
    <div class="mt-4 mt-sm-14 px-3 px-sm-8">
        <div class="container d-flex flex-column">
            <div class="search-elements-container d-flex flex-column">
                <div class="d-flex flex-row justify-space-between">
                    <h1>Manage Traits</h1>
                    <v-btn icon size="small" @click="newTraitModal = true">
                        <v-icon icon="mdi-plus" />
                        <v-tooltip activator="parent" location="end">
                            <span class="tooltip-text">
                                Create new trait
                            </span>
                        </v-tooltip>
                    </v-btn>
                </div>

                <div class="d-flex flex-row mt-10">
                    <div class="search-input-container">
                        <span class="text-subtitle-1">
                            You can filter traits either by attribute or by value.
                        </span>
                        <v-text-field v-model="searchInput" class="search-input mt-4" label="Attribute or Value"
                            variant="outlined" />
                        <v-btn block color="accent" flat size="large" @click="searchTraits">
                            Search
                            <v-icon icon="mdi-magnify" class="ml-3" />
                        </v-btn>
                    </div>
                </div>
            </div>

            <div v-if="showResult" class="mt-10">
                <div v-if="successQuery">
                    <h5 class="text-h5 mb-5">Traits found:</h5>
                    <div>
                        <v-table density="compact">
                            <thead>
                                <tr>
                                    <th v-for="header in headers" :key="header" class="text-left text-accent">
                                        {{ header }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in traitsArrayResult" :key="item">
                                    <td>{{ item.attribute ? item.attribute : '-' }}</td>
                                    <td>{{ item.value ? item.value : '-' }}</td>
                                    <td>{{ item.missions_qualified ? item.missions_qualified : '0' }}</td>
                                    <td>{{ item.weeks_qualified ? item.weeks_qualified : '0' }}</td>
                                    <td>{{ item.raids_qualified ? item.raids_qualified : '0' }}</td>
                                    <td>{{ item.rarity ? item.rarity : '-' }}</td>
                                    <td>{{ item.collection_percentage ? item.collection_percentage : '-' }}</td>
                                    <td>{{ item.tier_1 ? item.tier_1 : '-' }}</td>
                                    <td>{{ item.tier_2 ? item.tier_2 : '-' }}</td>
                                    <td>{{ item.tier_3 ? item.tier_3 : '-' }}</td>
                                    <td class="d-flex flex-row">
                                        <div>
                                            <v-icon icon="mdi-pencil" color="success" class="action-btn" @click="updateTrait(item)" />
                                            <v-tooltip activator="parent" location="start">
                                                Edit
                                            </v-tooltip>
                                        </div>

                                        <div>
                                            <v-icon icon="mdi-delete" color="error" class="action-btn ml-2" @click="askConfirmation(item.combined)" />
                                            <v-tooltip activator="parent" location="end">
                                                Delete
                                            </v-tooltip>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </v-table>
                    </div>
                </div>
                <div v-else>
                    <span class="empty-search-text">
                        Sorry, we couldn't find any trait.
                    </span>
                </div>
            </div>
        </div>

        <v-overlay v-model="loading" contained class="align-center justify-center">
            <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
        </v-overlay>

        <v-snackbar v-model="snackbar" :timeout="10000">
            {{ message }}

            <template v-slot:actions>
                <v-btn color="blue" variant="text" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <!-- Create new mission dialog -->
        <v-dialog v-model="newTraitModal" persistent>
            <TraitSchemaForm @close="newTraitModal = false" />
        </v-dialog>

        <!-- Update mission dialog -->
        <v-dialog v-model="updateTraitModal" persistent>
            <TraitSchemaForm edit-mode :id-to-update.sync="currentlyUpdatingId"
                :trait-to-update.sync="currentlyUpdatingTrait" @close="updateTraitModal = false" />
        </v-dialog>

        <v-dialog v-model="userConfirmationModal" persistent>
            <v-card class="confirmation-card pa-2">
                <v-card-title>
                    Are you sure you want to delete this element?
                </v-card-title>
                <v-card-actions class="d-flex justify-center">
                    <v-btn variant="tonal" @click="userConfirmationModal = false">
                        Cancel
                    </v-btn>
                    <v-btn variant="tonal" color="error" @click="handleDelete">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios'
import TraitSchemaForm from '@/components/Traits/TraitSchemaForm.vue'

const searchInput = ref('')
const traitsArrayResult = ref([])

const headers = ref(['Attribute', 'Value', 'Missions Qualified', 'Weeks Qualified', 'Raids Qualified', 'Rarity', 'Collection %', 'Tier 1', 'Tier 2', 'Tier 3', 'Actions'])

const showResult = ref(false)
const successQuery = ref(true)

const newTraitModal = ref(false)
const updateTraitModal = ref(false)

const currentlyUpdatingId = ref(null)
const currentlyUpdatingTrait = ref(null)

const currentlyDeletingTrait = ref(null)

const userConfirmationModal = ref(false)

const loading = ref(false)
const snackbar = ref(false)
const message = ref('')

async function searchTraits() {
    traitsArrayResult.value = []

    loading.value = true

    if (!searchInput.value.length) {
        snackbar.value = true
        message.value = 'Type something!'
        return
    }

    await axios.get(`https://ff-backend-api-kpojlq5hbq-uc.a.run.app/api/traits-search/${searchInput.value}`)
        .then(response => {
            traitsArrayResult.value = response.data
            successQuery.value = true
            showResult.value = true
        })
        .catch(error => {
            if (error.response.status === 404 && error.response.data.message === 'Traits not found') {
                successQuery.value = false
                showResult.value = true
            } else {
                snackbar.value = true
                message.value = error
            }
        })

    loading.value = false
}

async function updateTrait(payload) {
    updateTraitModal.value = true
    currentlyUpdatingId.value = payload.combined
    currentlyUpdatingTrait.value = payload
}

async function askConfirmation(id) {
    currentlyDeletingTrait.value = id
    userConfirmationModal.value = true
}

function handleDelete() {
    userConfirmationModal.value = false
    deleteTrait(currentlyDeletingTrait.value)
}

async function deleteTrait(combined) {
    loading.value = true
    await axios.delete(`https://ff-backend-api-kpojlq5hbq-uc.a.run.app/api/traits/${combined}`)
        .then(response => {
            snackbar.value = true
            message.value = 'Trait deleted succesfully!'
            traitsArrayResult.value = []
            showResult.value = false
        })
        .catch(error => {
            snackbar.value = true
            message.value = error
        })
    loading.value = false
}
</script>

<style scoped>
.search-elements-container {
    max-width: 644px;
}

.empty-search-text {
    font-size: 25px;
}

.action-btn {
    cursor: pointer;
}

.confirmation-card {
    max-width: 600px;
    margin: 0 auto;
}
</style>
