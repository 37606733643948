import { db } from '@/firebase'
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth"

export default {
    namespaced: true,
    state: {
        appName: 'FamousFoxes',
        user: {},
        loggedIn: false,
        isAdminUser: false,
        emptySearch: false
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user
        },
        SET_LOGGED_IN(state, value) {
            state.loggedIn = value
        },
        SET_IS_ADMIN_USER(state, value) {
            state.isAdminUser = value
        }
    },
    actions: {
        login({ commit }, credentials) {
            return new Promise(async (resolve, reject) => {
                const auth = getAuth()
                signInWithEmailAndPassword(auth, credentials.email, credentials.password)
                    .then(userCredential => {
                        commit('SET_USER', userCredential.user)
                        commit('SET_LOGGED_IN', true)
                        resolve(userCredential)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        logout({ commit }) {
            return new Promise(async (resolve, reject) => {
                const auth = getAuth()
                await signOut(auth)
                    .then(() => {
                        commit('SET_USER', null)
                        commit('SET_LOGGED_IN', false)
                        resolve('ok')
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        async checkUserPermissions({ commit }, email) {
            const docRef = doc(db, 'admin_users', email)
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                commit('SET_IS_ADMIN_USER', true)
            } else {
                commit('SET_IS_ADMIN_USER', false)
            }
        },
        createUser({ commit }, user) {
            return new Promise(async (resolve, reject) => {
                const auth = getAuth()
                await createUserWithEmailAndPassword(auth, user.email, user.password)
                    .then((response) => {
                        commit('SET_USER', user)
                        commit('SET_LOGGED_IN', true)
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        getCurrentUser({ commit }) {
            return new Promise((resolve, reject) => {
                const auth = getAuth()
                onAuthStateChanged(auth, (user) => {
                    if (user) {
                        commit('SET_USER', user)
                        commit('SET_LOGGED_IN', true)
                        resolve(user)
                    } else {
                        commit('SET_USER', null)
                        commit('SET_LOGGED_IN', false)
                        reject('not logged in')
                    }
                })
            })
        }
    },
    getters: {
        appName: (state) => {
            return state.appName
        },
        user: (state) => {
            return state.user
        },
        loggedIn: (state) => {
            return state.loggedIn
        },
        isAdminUser: (state) => {
            return state.isAdminUser
        }
    }
}