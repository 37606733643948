<template>
    <v-table density="compact">
        <thead>
            <tr>
                <th v-for="header in headers" :key="header" class="text-left text-accent">
                    <v-hover>
                        <template v-slot:default="{ isHovering, props }">
                            <span v-bind="props" class="cursor-pointer table-header" @click="emitFilter(header)">
                                {{ header.name }}
                                <v-icon v-if="isHovering" transition="fade-transition" :icon="descendingData ? 'mdi-chevron-up' : 'mdi-chevron-down'" size="x-small" />
                            </span>
                        </template>
                    </v-hover>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in foxesData" :key="item.number">
                <td>{{ item.fox.number ? item.fox.number : '-' }}</td>
                <td class="cell-image-container">
                    <img v-if="item.fox.image" :src="item.fox.image" class="fox-image"
                        @click="displayImage(item.fox.image)">
                    <img v-else :src="require('@/assets/images/no-image-placeholder.jpg')" class="no-image-placeholder"
                        alt="No image">
                </td>
                <td>{{ item.fox.rank ? item.fox.rank : '-' }}</td>
                <td :class="!item.fox.weeks_qualified ? 'disabled-text' : ''">
                    {{ item.fox.weeks_qualified ? item.fox.weeks_qualified : '0' }}
                </td>
                <td :class="!item.fox.missions_qualified ? 'disabled-text' : ''">
                    {{ item.fox.missions_qualified ? item.fox.missions_qualified : '0' }}
                </td>
                <td :class="!item.fox.raids_qualified ? 'disabled-text' : ''">
                    {{ item.fox.raids_qualified ? item.fox.raids_qualified : '0' }}
                </td>
                <td>{{ item.fox.background ? item.fox.background : '-' }}</td>
                <td>{{ item.fox.edition ? item.fox.edition : '-' }}</td>
                <td>{{ item.fox.outfit ? item.fox.outfit : '-' }}</td>
                <td>{{ item.fox.skin ? item.fox.skin : '-' }}</td>
                <td>{{ item.fox.mouth ? item.fox.mouth : '-' }}</td>
                <td>{{ item.fox.head ? item.fox.head : '-' }}</td>
                <td>{{ item.fox.eyes ? item.fox.eyes : '-' }}</td>
                <td>
                    {{ item.fox.mint ? trimmedField(item.fox.mint) : '-' }}
                    <v-icon v-if="item.fox.mint" icon="mdi-content-copy" class="pl-4" size="x-small" @click="copyToClipboard(item.fox.mint)" />
                    <v-tooltip activator="parent" location="end">
                        <span class="tooltip-text">
                            {{ item.fox.mint }}
                        </span>
                    </v-tooltip>
                </td>
                <td>
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <div color="primary" v-bind="props" class="traits-menu-activator">
                                {{ item.traits[0] + '...' }} <v-icon icon="mdi-chevron-down"></v-icon>
                            </div>
                        </template>
                        <v-list>
                            <v-list-item v-for="(trait, index) in item.traits" :key="index" :value="trait">
                                <v-list-item-title>{{ trait }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </td>
            </tr>
        </tbody>

        <v-overlay :model-value="imageOverlay" @afterLeave="closeImageOverlay" class="align-center justify-center">
            <img :src="imageDisplayed" alt="NFT Image">
        </v-overlay>

        <v-snackbar v-model="snackbar" :timeout="10000">
            {{ message }}

            <template v-slot:actions>
                <v-btn color="blue" variant="text" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-table>
</template>

<script setup>
import { ref, defineEmits } from 'vue'

const props = defineProps({
    foxesData: Array,
    descendingData: Boolean
})

const emit = defineEmits(['setFilter'])

const headers = ref([
    { id: 'number', name: '#' },
    { id: 'image', name: 'Image' },
    { id: 'rank', name: 'Rank' },
    { id: 'weeks_qualified', name: 'Weeks Qualified' },
    { id: 'missions_qualified', name: 'Missions Qualified' },
    { id: 'raids_qualified', name: 'Raids Qualified' },
    { id: 'background', name: 'Background' },
    { id: 'edition', name: 'Edition' },
    { id: 'outfit', name: 'Outfit' },
    { id: 'skin', name: 'Skin' },
    { id: 'mouth', name: 'Mouth' },
    { id: 'head', name: 'Head' },
    { id: 'eyes', name: 'Eyes' },
    { id: 'mint', name: 'Mint' },
    { id: 'all_traits', name: 'All Traits' } 
])

const imageOverlay = ref(false)
const imageDisplayed = ref('')

const snackbar = ref(false)
const message = ref('')

function trimmedField(field) {
    if (field.length > 20) {
        return field.slice(0, 17) + '...'
    } else {
        return field
    }
}

function displayImage(image) {
    imageDisplayed.value = image
    imageOverlay.value = true
}

function closeImageOverlay() {
    imageDisplayed.value = ''
    imageOverlay.value = false
}

function copyToClipboard(text) {
    navigator.clipboard.writeText(text)
    snackbar.value = true
    message.value = 'Copied to clipboard!'
}

function emitFilter(header) {
    emit('setFilter', header.id)
}
</script>

<style scoped>
.disabled-text {
    color: #989898;
}

.cursor-pointer {
    cursor: pointer;
}

.table-header {
    transition: all 0.2s ease;
}

.table-header:hover {
    color: #00E5FF;
}

.cell-image-container {
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
}

.fox-image,
.no-image-placeholder {
    object-fit: contain;
    width: 50px;
    transform: translate(0px, 3px);
}

.traits-menu-activator {
    cursor: pointer;
}

.tooltip-text {
    font-size: 20px;
}
</style>