<template>
    <v-card class="new-fox-card pa-5">
        <v-card-title>
            <h5 class="text-h5">
                {{ title }}
            </h5>
        </v-card-title>
        <v-card-text class="px-0 px-md-2">
            <v-form ref="formRef" v-model="formValid" lazy-validation>
                <v-container class="px-0">
                    <v-row no-gutters>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newFox.number" variant="outlined" type="number" density="compact" :rules="formRules" class="py-0 px-2" label="Number" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newFox.rank" variant="outlined" type="number" density="compact" :rules="formRules" class="py-0 px-2" label="Rank" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newFox.skin" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Skin" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newFox.outfit" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Outfit" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newFox.mouth" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Mouth" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newFox.mint" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Mint" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newFox.image" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Image" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newFox.head" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Head" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newFox.eyes" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Eyes" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newFox.edition" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Edition" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newFox.background" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Background" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newFox.all_traits" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="All Traits" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newFox.missions_qualified" type="number" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Missions Qualified" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newFox.weeks_qualified" type="number" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Weeks Qualified" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newFox.raids_qualified" type="number" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Raids Qualified" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
            <v-btn @click="handleClose">
                Cancel
            </v-btn>
            <v-btn color="accent" :loading="loading" @click="handleAction">
                Save
            </v-btn>
        </v-card-actions>

        <v-snackbar v-model="snackbar" :timeout="10000">
            {{ message }}

            <template v-slot:actions>
                <v-btn color="blue" variant="text" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <v-overlay v-model="loading" contained class="align-center justify-center">
            <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
        </v-overlay>
    </v-card>
</template>

<script setup>
import { ref, defineEmits, onMounted } from 'vue'
import axios from 'axios'

const props = defineProps({
    foxToUpdate: Object,
    editMode: Boolean,
    idToUpdate: String
})
const emit = defineEmits(['cancel'])

const snackbar = ref(false)
const message = ref('')
const loading = ref(false)

const title = ref('New Fox')

const formRef = ref(null)
const formValid = ref(false)

const formRules = ref([
    v => !!v || 'This field is required'
])

const newFox = ref({
    number: '',
    rank: '',
    skin: '',
    outfit: '',
    mouth: '',
    mint: '',
    image: '',
    head: '',
    eyes: '',
    edition: '',
    background: '',
    all_traits: '',
    missions_qualified: 0,
    weeks_qualified: 0,
    raids_qualified: 0
})

onMounted(() => {
    if (props.editMode) {
        newFox.value = props.foxToUpdate
        title.value = 'Update Fox'
    }
})

function handleClose() {
    emit('close')
}

async function handleAction() {
    if (validateForm()) {
        loading.value = true
        if (!props.editMode) {
            // create fox
            
            // parse int values
            newFox.value.missions_qualified = parseInt(newFox.value.missions_qualified)
            newFox.value.weeks_qualified = parseInt(newFox.value.weeks_qualified)
            newFox.value.raids_qualified = parseInt(newFox.value.raids_qualified)

            await axios.post('https://ff-backend-api-kpojlq5hbq-uc.a.run.app/api/foxes', newFox.value)
                .then(response => {
                    emit('created')
                    handleClose()
                })
                .catch(error => {
                    snackbar.value = true
                    message.value = error
                })
        } else {
            // update fox

            // parse int values
            newFox.value.missions_qualified = parseInt(newFox.value.missions_qualified)
            newFox.value.weeks_qualified = parseInt(newFox.value.weeks_qualified)
            newFox.value.raids_qualified = parseInt(newFox.value.raids_qualified)

            await axios.patch(`https://ff-backend-api-kpojlq5hbq-uc.a.run.app/api/foxes/${parseInt(newFox.value.number)}`, newFox.value)
                .then(response => {
                    emit('updated')
                    handleClose()
                })
                .catch(error => {
                    snackbar.value = true
                    message.value = error
                })
        }
        loading.value = false
    } else {
        snackbar.value = true
        message.value = 'Fill at least the fox number'
    }
}

function validateForm() {
    return (newFox.value.number != 0 && newFox.value.number != '') || newFox.value.number == 0
}

</script>

<style scoped>
.new-fox-card {
    margin: 0 auto;
    width: 60%;
}

@media screen and (max-width: 768px) {
    .new-fox-card {
        width: 100%;
    }
}
</style>