<template>
    <v-card class="new-mission-card pa-5">
        <v-card-title>
            <h5 class="text-h5">
                {{ title }}
            </h5>
        </v-card-title>
        <v-card-text class="px-0 px-md-2">
            <v-form ref="formRef" v-model="formValid" lazy-validation>
                <v-container class="px-0">
                    <v-row no-gutters>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newMission.date" variant="outlined" density="compact" class="py-0 px-2" label="Date (MM/DD/YYYY)" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newMission.week" variant="outlined" density="compact" class="py-0 px-2" label="Week" type="number" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newMission.mission_id" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Mission ID" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newMission.collab" variant="outlined" density="compact" class="py-0 px-2" label="Collab" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newMission.trait" variant="outlined" density="compact" :rules="formRules" class="py-0 px-2" label="Trait" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newMission.foxes_required" variant="outlined" density="compact" class="py-0 px-2" label="Foxes Required" type="number" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newMission.level" variant="outlined" density="compact" class="py-0 px-2" label="Level" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newMission.den" variant="outlined" density="compact" class="py-0 px-2" label="Den" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newMission.qualifying_traits" variant="outlined" density="compact" class="py-0 px-2" label="Qualifying Traits" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newMission.rarity" variant="outlined" density="compact" class="py-0 px-2" label="Rarity" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newMission.fame_reward" variant="outlined" density="compact" class="py-0 px-2" label="FFF Fame Reward" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newMission.fame_bump" variant="outlined" density="compact" class="py-0 px-2" label="FFF w lvl 40 Bump" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newMission.tff_fame_reward" variant="outlined" density="compact" class="py-0 px-2" label="TFF Fame Reward" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newMission.tff_fame_bump" variant="outlined" density="compact" class="py-0 px-2" label="TFF w lvl 40 Bump" />
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-text-field v-model="newMission.discord_announcement_link" variant="outlined" density="compact" class="py-0 px-2" label="Discord Announcement Link" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
            <v-btn @click="handleClose">
                Cancel
            </v-btn>
            <v-btn color="accent" :loading="loading" @click="handleAction">
                Save
            </v-btn>
        </v-card-actions>

        <v-snackbar v-model="snackbar" :timeout="10000">
            {{ message }}

            <template v-slot:actions>
                <v-btn color="blue" variant="text" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <v-overlay v-model="loading" contained class="align-center justify-center">
            <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
        </v-overlay>
    </v-card>
</template>

<script setup>
import { ref, defineEmits, onMounted } from 'vue'
import axios from 'axios'

const props = defineProps({
    missionToUpdate: Object,
    editMode: Boolean,
    idToUpdate: String
})
const emit = defineEmits(['cancel'])

const snackbar = ref(false)
const message = ref('')
const loading = ref(false)

const title = ref('New Mission')

const formRef = ref(null)
const formValid = ref(false)

const formRules = ref([
    v => !!v || 'This field is required'
])

const newMission = ref({
    date: '',
    week: 0,
    mission_id: '',
    collab: '',
    trait: '',
    foxes_required: 0,
    level: '',
    den: '',
    qualifying_traits: '',
    rarity: '',
    fame_reward: '',
    fame_bump: '',
    tff_fame_reward: '',
    tff_fame_bump: '',
    discord_announcement_link: '',
    note: ''
})

onMounted(() => {
    if (props.editMode) {
        newMission.value = props.missionToUpdate
        title.value = 'Update Mission ' + props.missionToUpdate.mission_id
    }
})

function handleClose() {
    emit('close')
}

async function handleAction() {
    if (validateForm()) {
        loading.value = true
        if (!props.editMode) {
            // create mission
            await axios.post('https://ff-backend-api-kpojlq5hbq-uc.a.run.app/api/missions', newMission.value)
                .then(response => {
                    emit('created')
                    handleClose()
                })
                .then(async () => {
                    loading.value = false
                    // update missions and weeks values in foxes ranked 1 to 7
                    await axios.get('https://ff-backend-api-kpojlq5hbq-uc.a.run.app/api/update-missions-count')
                    await axios.get('https://ff-backend-api-kpojlq5hbq-uc.a.run.app/api/update-weeks-count')
                })
                .catch(error => {
                    snackbar.value = true
                    message.value = error
                })
        } else {
            // update mission
            await axios.patch(`https://ff-backend-api-kpojlq5hbq-uc.a.run.app/api/missions/${newMission.value.mission_id}`, newMission.value)
                .then(response => {
                    emit('updated')
                    handleClose()
                })
                .catch(error => {
                    snackbar.value = true
                    message.value = error
                })
        }
        loading.value = false
    } else {
        snackbar.value = true
        message.value = 'Fill at least the mission ID.'
    }
}

function validateForm() {
    return newMission.value.mission_id.length && newMission.value.trait.length
}

</script>

<style scoped>
.new-mission-card {
    margin: 0 auto;
    width: 60%;
}

@media screen and (max-width: 768px) {
    .new-mission-card {
        width: 100%;
    }
}
</style>