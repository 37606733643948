<template>
    <div class="mt-4 mt-sm-14 px-3 px-sm-8">
        <div class="container d-flex flex-column">
            <div class="search-elements-container d-flex flex-column">
                <div class="d-flex flex-row justify-space-between">
                    <h1>Manage Missions</h1>
                    <v-btn icon size="small" @click="newMissionModal = true">
                        <v-icon icon="mdi-plus" />
                        <v-tooltip activator="parent" location="end">
                            <span class="tooltip-text">
                                Create new mission
                            </span>
                        </v-tooltip>
                    </v-btn>
                </div>

                <div class="d-flex flex-row mt-10">
                    <div class="search-input-container">
                        <span class="text-subtitle-1">
                            You can search a specific mission by it's ID.
                        </span>
                        <v-text-field v-model="searchInput" class="search-input mt-4" label="Mission ID"
                            variant="outlined" />
                        <v-btn block color="accent" flat size="large" @click="search">
                            Search
                            <v-icon icon="mdi-magnify" class="ml-3" />
                        </v-btn>
                    </div>
                </div>
            </div>

            <div v-if="showResult" class="mt-10">
                <div v-if="successQuery">
                    <h5 class="text-h5 mb-5">Missions found:</h5>
                    <div>
                        <v-table density="compact">
                            <thead>
                                <tr>
                                    <th v-for="header in headers" :key="header" class="text-left text-accent">
                                        {{ header }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in missionsArrayResult" :key="item">
                                    <td>{{ item.mission_id ? item.mission_id : '-' }}</td>
                                    <td>{{ item.date ? item.date : '-' }}</td>
                                    <td>{{ item.week ? item.week : '-' }}</td>
                                    <td>{{ item.trait ? item.trait : '-' }}</td>
                                    <td>{{ item.level ? item.level : '-' }}</td>
                                    <td>{{ item.rarity ? item.rarity : '-' }}</td>
                                    <td>{{ item.foxes_required ? item.foxes_required : '-' }}</td>
                                    <td>
                                        <a :href="item.discord_announcement_link ? item.discord_announcement_link : '#'"
                                            target="_blank">
                                            {{
                                                item.discord_announcement_link ?
                                                item.discord_announcement_link.slice(0, 35) + '...' : '-'
                                            }}
                                        </a>
                                    </td>
                                    <td class="d-flex flex-row">
                                        <div>
                                            <v-icon icon="mdi-pencil" color="success" class="action-btn"
                                                @click="updateMission(item)" />
                                            <v-tooltip activator="parent" location="start">
                                                Edit
                                            </v-tooltip>
                                        </div>

                                        <div>
                                            <v-icon icon="mdi-delete" color="error" class="action-btn ml-2"
                                                @click="askConfirmation(item.mission_id)" />
                                            <v-tooltip activator="parent" location="end">
                                                Delete
                                            </v-tooltip>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </v-table>
                    </div>
                </div>
                <div v-else>
                    <span class="empty-search-text">
                        Sorry, we couldn't find any mission with that ID.
                    </span>
                </div>
            </div>
        </div>

        <v-overlay v-model="loading" contained class="align-center justify-center">
            <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
        </v-overlay>

        <v-snackbar v-model="snackbar" :timeout="10000">
            {{ message }}

            <template v-slot:actions>
                <v-btn color="blue" variant="text" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <!-- Create new mission dialog -->
        <v-dialog v-model="newMissionModal" persistent>
            <MissionSchemaForm @created="showSuccess" @close="newMissionModal = false" />
        </v-dialog>

        <!-- Update mission dialog -->
        <v-dialog v-model="updateMissionModal" persistent>
            <MissionSchemaForm edit-mode :id-to-update.sync="currentlyUpdatingId"
                :mission-to-update.sync="currentlyUpdatingMission" @updated="showSuccessUpdated" @close="updateMissionModal = false" />
        </v-dialog>

        <v-dialog v-model="userConfirmationModal" persistent>
            <v-card class="confirmation-card pa-2">
                <v-card-title>
                    Are you sure you want to delete this element?
                </v-card-title>
                <v-card-actions class="d-flex justify-center">
                    <v-btn variant="tonal" @click="userConfirmationModal = false">
                        Cancel
                    </v-btn>
                    <v-btn variant="tonal" color="error" @click="handleDelete">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios'
import MissionSchemaForm from '@/components/Missions/MissionSchemaForm.vue'

const searchInput = ref('')
const missionsArrayResult = ref([])

const headers = ref(['ID', 'Date', 'Week', 'Trait', 'Level', 'Rarity', 'Foxes Required', 'Discord Link', 'Actions'])

const showResult = ref(false)
const successQuery = ref(true)

const newMissionModal = ref(false)
const updateMissionModal = ref(false)

const currentlyUpdatingId = ref(null)
const currentlyUpdatingMission = ref(null)

const currentlyDeletingMission = ref(null)

const userConfirmationModal = ref(false)

const loading = ref(false)
const snackbar = ref(false)
const message = ref('')

async function search() {
    missionsArrayResult.value = []

    if (!searchInput.value.length) {
        snackbar.value = true
        message.value = 'Type something!'
        return
    }

    loading.value = true

    await axios.get(`https://ff-backend-api-kpojlq5hbq-uc.a.run.app/api/missions/${searchInput.value}`)
        .then(response => {
            missionsArrayResult.value.push(response.data)
            successQuery.value = true
            showResult.value = true
        })
        .catch(error => {
            if (error.response.status === 404 && error.response.data.message === 'Mission not found') {
                successQuery.value = false
                showResult.value = true
            } else {
                snackbar.value = true
                message.value = error
            }
        })

    loading.value = false
}

async function updateMission(payload) {
    updateMissionModal.value = true
    currentlyUpdatingId.value = payload.mission_id
    currentlyUpdatingMission.value = payload
}

async function askConfirmation(mission_id) {
    currentlyDeletingMission.value = mission_id
    userConfirmationModal.value = true
}

function handleDelete() {
    userConfirmationModal.value = false
    deleteMission(currentlyDeletingMission.value)
}

async function deleteMission(mission_id) {
    loading.value = true
    await axios.delete(`https://ff-backend-api-kpojlq5hbq-uc.a.run.app/api/missions/${mission_id}`)
        .then(response => {
            snackbar.value = true
            message.value = 'Mission deleted succesfully!'
            missionsArrayResult.value = []
            showResult.value = false
        })
        .catch(error => {
            snackbar.value = true
            message.value = error
        })
    loading.value = false
}

function showSuccess() {
    snackbar.value = true
    message.value = "Mission created succesfully!"
}

function showSuccessUpdated() {
    snackbar.value = true
    message.value = "Mission updated succesfully!"
}
</script>

<style scoped>
.search-elements-container {
    max-width: 644px;
}

.empty-search-text {
    font-size: 25px;
}

.action-btn {
    cursor: pointer;
}

.confirmation-card {
    max-width: 600px;
    margin: 0 auto;
}
</style>
