<template>
    <v-card class="new-raid-card pa-5">
        <v-card-title>
            <h5 class="text-h5">
                {{ title }}
            </h5>
        </v-card-title>
        <v-card-text class="px-0 px-md-2">
            <v-form ref="formRef" v-model="formValid" lazy-validation>
                <v-container class="px-0">
                    <v-row no-gutters>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="newRaid.date" variant="outlined" density="compact" :rules="formRules"
                                class="py-0 px-2" label="Date (MM/DD/YYYY)" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="newRaid.order_number" variant="outlined" density="compact"
                                :rules="formRules" class="py-0 px-2" label="Order" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="newRaid.fame_req" variant="outlined" density="compact" :rules="formRules"
                                class="py-0 px-2" label="Fame Req" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="newRaid.FFF_fame" variant="outlined" density="compact" :rules="formRules"
                                class="py-0 px-2" label="FFF Fame" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="newRaid.TFF_fame" variant="outlined" density="compact" :rules="formRules"
                                class="py-0 px-2" label="TFF Fame" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="newRaid.trait" variant="outlined" density="compact" :rules="formRules"
                                class="py-0 px-2" label="Trait" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="newRaid.all_traits" variant="outlined" density="compact"
                                :rules="formRules" class="py-0 px-2" label="All Traits" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="newRaid.number_of_foxes" variant="outlined" density="compact"
                                :rules="formRules" class="py-0 px-2" label="Number of Foxes" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="newRaid.discord_announcement_link" variant="outlined" density="compact"
                                class="py-0 px-2" label="Discord Announcement Link" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
            <v-btn @click="handleClose">
                Cancel
            </v-btn>
            <v-btn color="accent" :loading="loading" @click="handleAction">
                Save
            </v-btn>
        </v-card-actions>

        <v-snackbar v-model="snackbar" :timeout="10000">
            {{ message }}

            <template v-slot:actions>
                <v-btn color="blue" variant="text" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <v-overlay v-model="loading" contained class="align-center justify-center">
            <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
        </v-overlay>
    </v-card>
</template>

<script setup>
import { ref, defineEmits, onMounted } from 'vue'
import axios from 'axios'

const props = defineProps({
    raidToUpdate: Object,
    editMode: Boolean,
    idToUpdate: String
})
const emit = defineEmits(['cancel'])

const snackbar = ref(false)
const message = ref('')
const loading = ref(false)

const title = ref('New Raid')

const formRef = ref(null)
const formValid = ref(false)

const formRules = ref([
    v => !!v || 'This field is required'
])

const newRaid = ref({
    date: '',
    fame_req: '',
    FFF_fame: '',
    TFF_fame: '',
    trait: '',
    all_traits: '',
    order_number: '',
    number_of_foxes: '',
    discord_announcement_link: '',
})

onMounted(() => {
    if (props.editMode) {
        newRaid.value = props.raidToUpdate
        title.value = 'Update Raid'
    }
})

function handleClose() {
    emit('close')
}

async function handleAction() {
    if (validateForm) {
        loading.value = true
        if (!props.editMode) {
            // create raid
            await axios.post('https://ff-backend-api-kpojlq5hbq-uc.a.run.app/api/raids', newRaid.value)
                .then(response => {
                    console.log('created raid?:', response.data)
                    emit('created')
                    handleClose()
                })
                .then(response => {
                    // increment raids for foxes ranked 1 to 7
                    axios.get('https://ff-backend-api-kpojlq5hbq-uc.a.run.app/api/update-raids-count')
                })
                .catch(error => {
                    snackbar.value = true
                    message.value = error
                })
        } else {
            // update raid
            await axios.patch(`https://ff-backend-api-kpojlq5hbq-uc.a.run.app/api/raids/${newRaid.value.order_number}`, newRaid.value)
                .then(response => {
                    console.log('updated raid?:', response.data)
                    emit('updated')
                    handleClose()
                })
                .catch(error => {
                    snackbar.value = true
                    message.value = error
                })
        }
        loading.value = false
    } else {
        snackbar.value = true
        message.value = 'Fill at least order, date and trait.'
    }
}

function validateForm() {
    return newRaid.value.date.length && newRaid.value.trait.length && newRaid.value.order_number.length
}

</script>

<style scoped>
.new-raid-card {
    margin: 0 auto;
    width: 60%;
}

@media screen and (max-width: 768px) {
    .new-raid-card {
        width: 100%;
    }
}
</style>