import { createStore } from "vuex"
import auth from './modules/auth'
import foxes from './modules/foxes'
import missions from './modules/missions'
import traits from './modules/traits'
import raids from './modules/raids'

export default createStore({
    modules: {
        auth,
        foxes,
        missions,
        traits,
        raids
    }
})