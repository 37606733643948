<template>
    <v-table density="compact">
        <thead>
            <tr>
                <th v-for="header in headers" :key="header" class="text-left text-accent">
                    <v-hover>
                        <template v-slot:default="{ isHovering, props }">
                            <span v-bind="props" class="cursor-pointer table-header" @click="emitFilter(header)">
                                {{ header.name }}
                                <v-icon v-if="isHovering" transition="fade-transition" :icon="descendingData ? 'mdi-chevron-up' : 'mdi-chevron-down'" size="x-small" />
                            </span>
                        </template>
                    </v-hover>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in traitsData" :key="item.name">
                <td>{{ item.attribute ? item.attribute : '-' }}</td>
                <td>{{ item.value ? item.value : '-' }}</td>
                <td :class="!item.missions_qualified ? 'disabled-text' : 'strong-text'">{{
                    item.missions_qualified ? item.missions_qualified
                    : '0'
                }}</td>
                <!-- <td>{{ item.combined ? item.combined : '-' }}</td> -->
                <td :class="!item.weeks_qualified ? 'disabled-text' : 'strong-text'">{{
                    item.weeks_qualified ? item.weeks_qualified
                    : '0'
                }}</td>
                <td :class="!item.raidsQualified ? 'disabled-text' : 'strong-text'">{{
                    item.raidsQualified ? item.raidsQualified
                    : '0'
                }}</td>
                <td>{{ item.rarity ? item.rarity : '-' }}</td>
                <td>{{ item.tier_1 ? item.tier_1 : '-' }}</td>
                <td>{{ item.tier_2 ? item.tier_2 : '-' }}</td>
                <td>{{ item.tier_3 ? item.tier_3 : '-' }}</td>
                <td>{{ item.collection_percentage ? item.collection_percentage : '-' }}</td>
            </tr>
        </tbody>
    </v-table>
</template>

<script setup>
import { ref, defineEmits } from 'vue'

const props = defineProps({
    traitsData: Array,
    descendingData: Boolean
})

console.log('traits in table:', props.traitsData)

const emit = defineEmits(['setFilter'])

const headers = ref([
    { id: 'attribute', name: 'Attribute' },
    { id: 'value', name: 'Value' },
    { id: 'missions_qualified', name: 'Missions Qualified' },
    { id: 'weeks_qualified', name: 'Weeks Qualified' },
    { id: 'raids_qualified', name: 'Raids Qualified' },
    { id: 'rarity', name: 'Rarity' },
    { id: 'tier_1', name: 'Tier 1' },
    { id: 'tier_2', name: 'Tier 2' },
    { id: 'tier_3', name: 'Tier 3' },
    { id: 'collection_percentage', name: 'Colection %' }
])

function emitFilter(header) {
    emit('setFilter', header.id)
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}

.table-header {
    transition: all 0.2s ease;
}

.table-header:hover {
    color: #00E5FF;
}

.disabled-text {
    color: #989898;
}

.strong-text {
    font-weight: bold;
}
</style>