import { createWebHistory, createRouter } from 'vue-router';

import Traits from '@/views/Traits.vue';
import Foxes from '@/views/Foxes.vue';
import SearchNFT from '@/views/SearchNFT.vue';

/** admin components */
import Menu from '@/views/admin/Menu.vue';
import Missions from '@/views/admin/Missions.vue';
import Raids from '@/views/admin/Raids.vue'
import TraitsAdmin from '@/views/admin/Traits.vue'
import FoxesAdmin from '@/views/admin/Foxes.vue'

import Error from '@/views/Error.vue';

const routes = [
	{
		path: '/',
		name: 'Traits',
		component: Traits,
        alias: '/traits'
	},
    {
        path: '/foxes',
        name: 'foxes-fff',
        component: Foxes,
    },
    {
        path: '/search',
        name: 'SearchNFT',
        component: SearchNFT
    },
    {
        path: '/admin',
        name: 'Menu',
        component: Menu
    },
    {
        path: '/admin/missions',
        name: 'Missions',
        component: Missions
    },
    {
        path: '/admin/raids',
        name: 'Raids',
        component: Raids
    },
    {
        path: '/admin/traits',
        name: 'TraitsAdmin',
        component: TraitsAdmin
    },
    {
        path: '/admin/foxes',
        name: 'FoxesAdmin',
        component: FoxesAdmin
    },
    {
        path: '/error',
        name: 'Error',
        component: Error
    }
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;