import axios from 'axios'

export default {
    namespaced: true,
    state: {
        traits: []
    },
    mutations: {
        SET_TRAITS(state, payload) {
            state.traits = payload
        }
    },
    actions: {
        async getTraits({ commit }, parameters) {
            const { orderBy, direction, page, size } = parameters
            let url = 'https://ff-backend-api-kpojlq5hbq-uc.a.run.app/api/traits'

            url += '?page=' + page

            if (orderBy != null) url += '&orderBy=' + orderBy
            if (direction != null) url += '&direction=' + direction
            if (size != null) url += '&size=' + size

            await axios.get(url).then(response => {
                commit('SET_TRAITS', response)
            })
            .catch(error => {
                console.log('error getting traits:', error)
            })
        }
    },
    getters: {
        traits: (state) => {
            return state.traits
        }
    }
}