import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCHXPcAc-0wphxYqsRunoUZZT2pFw6Ua3A",
  authDomain: "famousfoxes-cdb36.firebaseapp.com",
  projectId: "famousfoxes-cdb36",
  storageBucket: "famousfoxes-cdb36.appspot.com",
  messagingSenderId: "62616383783",
  appId: "1:62616383783:web:2d3cf3eb8d9f62c9daf902"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();

export { db, auth }
